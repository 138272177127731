import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Helpers from './helpers';
import makeRows, { SUSTAINED_RTP_ROWS } from './makeRows';
import {
  STEP,
  LEVEL,
  STATE_RTP,
  RTP_REFERENCED_STEPS,
  DYNAMIC_TABS_STEPS,
  INTERLINKED_DATA_STEPS
} from './StateReturnToProvision.constants';

import { ContentAndTitle, TabTitle, StateSelector } from '..';
import { TableWithComment } from '../../..';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { Row, StateTabProps, SubJurisdictionWithCompletion } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { setInterlinkedDataWarningEntities } from '../../../../redux/ui';
import { selectDoesUserHaveRole } from '../../../../selectors';
import HTTPService, { LambdaResponse } from '../../../../services/http';
import LoadingWrapper from '../../../LoadingWrapper';
import { renderCell } from '../../../Table/components/TableBody';
import { Column, FailedCells, TableProps } from '../../../Table/Table.proptype';
import { ProReadOnly, ProReviewer } from '../../../UserRoleStylesProvider/constants';
import { getCreditNamesFromFinancialData } from '../../creditUtils';
import useClickOutsideDialog from '../../hooks/useClickOutsideDialog';
import {
  getRTPAttributeColumns,
  EntityNumberRouteMatch,
  getRowNamesAndSectionsFromLevelSteps,
  createSourceObject,
  handleOnCellOrCommentBlurForEntityDetails
} from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';
import BalanceCalculationsModal from '../BalanceCalculationsModal';
import InterlinkedDataDialog from '../InterlinkedDataDialog';
import RtpColumnIcons from '../RtpColumnIcons';

const { getFederalTaxableIncomeLossBeforeStateTaxAndNol, getRowByStateValue } = Helpers;

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const StateReturnToProvision = ({ entityId, states }: StateTabProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const classes = useStyles({ isUserReadOnly });
  const { prov2818InterlinkData, prov4280OnlyEfeReportsView: disablePriorYearError } = useFlags();
  const shouldShowRtpIcon = prov2818InterlinkData;
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;

  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();

  const [currentState, setCurrentState] = useState<SubJurisdictionWithCompletion>();
  const [statesWithCompletion, setStatesWithCompletion] = useState<SubJurisdictionWithCompletion[]>([]);
  const [nolRtpTotal, setNolRtpTotal] = useState<number>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [creditNameForModal, setCreditNameForModal] = useState('');

  const dialogRef = useRef<HTMLDivElement | null>(null);
  const buttonRef: any = useRef<any>();
  const history = useHistory();

  useEffect(() => {
    if (!isBalanceModalOpen) setCreditNameForModal('');
  }, [isBalanceModalOpen]);

  useClickOutsideDialog(dialogRef, setIsDialogOpen);

  const { tabsData: rtpTabsData, isFetchLoading: rtpIsFetchLoading, failedCells } = useFinancialData(
    entityNumber,
    LEVEL,
    [STEP],
    currentState?.id
  );
  const { tabsData: dynamicTabsData, isFetchLoading: dynamicIsFetchLoading } = useFinancialData(
    entityNumber,
    LEVEL,
    DYNAMIC_TABS_STEPS
  );
  const { tabsData: federalRtpTabsData, isFetchLoading: federalRtpIsFetchLoading } = useFinancialData(
    entityNumber,
    'federal',
    ['ptbi', 'rtp']
  );

  const stateSpecificTabsData = useMemo(
    () => getRowByStateValue(dynamicTabsData, LEVEL, RTP_REFERENCED_STEPS, currentState?.id),
    [currentState?.id, dynamicTabsData]
  );

  const federalTaxableIncomeBeforeStateTaxAndNOLTaxProvision = getFederalTaxableIncomeLossBeforeStateTaxAndNol(
    federalRtpTabsData,
    'taxProvision'
  );

  const federalTaxableIncomeBeforeStateTaxAndNOLTaxReturn = getFederalTaxableIncomeLossBeforeStateTaxAndNol(
    federalRtpTabsData,
    'taxReturn'
  );

  const financialInfo = useMemo(() => rtpTabsData[STATE_RTP] ?? [], [rtpTabsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HTTPService.request<LambdaResponse<any>>({
          method: 'get',
          apiUrlKey: 'apiUrl',
          relativePath: '/v1/container-rtp-financials'
        });
        dispatch(setInterlinkedDataWarningEntities(data));
      } catch (error: unknown) {
        console.error('Error fetching container data', error);
      }
    };

    void fetchData();
  }, [dispatch, financialInfo]);

  const rowNamesFromOtherTabs = useMemo(() => {
    const rowName = getRowNamesAndSectionsFromLevelSteps(stateSpecificTabsData, LEVEL, RTP_REFERENCED_STEPS);
    return {
      permanent: rowName?.['modifications.permanent'],
      temporary: rowName?.['modifications.temporary'],
      taxEffected: rowName['tax-effected'],
      credits: getCreditNamesFromFinancialData(stateSpecificTabsData['state.credits'] ?? []).map((name) => ({
        name,
        creditName: 'state.credits'
      }))
    };
  }, [stateSpecificTabsData]);

  const source = useMemo(() => {
    const sourceObject = createSourceObject(financialInfo, { ...SUSTAINED_RTP_ROWS, ...rowNamesFromOtherTabs });
    (sourceObject.federalTaxableIncomeBeforeStateTaxAndNOL as any).taxProvision = federalTaxableIncomeBeforeStateTaxAndNOLTaxProvision;
    (sourceObject.federalTaxableIncomeBeforeStateTaxAndNOL as any).taxReturn = federalTaxableIncomeBeforeStateTaxAndNOLTaxReturn;
    return sourceObject;
  }, [
    federalTaxableIncomeBeforeStateTaxAndNOLTaxProvision,
    federalTaxableIncomeBeforeStateTaxAndNOLTaxReturn,
    financialInfo,
    rowNamesFromOtherTabs
  ]);

  const { entityCompletionStatus: currentEntity, stepCompletionStatus } = useCompletionStatus(
    entityNumber,
    STATE_RTP,
    currentState?.id
  );

  const { completionStatus } = currentEntity;

  const nolLevelAndStep = `${LEVEL}.nol`;
  const creditsLevelandStep = `${LEVEL}.credits`;
  const nolInterlinkedRowName = 'Post-Apportioned Net Operating Loss';
  const { tabsData } = useFinancialData(entityNumber, LEVEL, ['nol', 'credits'], currentState?.id);
  const nolFinancialInfo = useMemo(() => tabsData[nolLevelAndStep] ?? [], [nolLevelAndStep, tabsData]);
  const nolRtpValues = useMemo(() => {
    return nolFinancialInfo.filter(({ columnName }) => columnName === 'rtp');
  }, [nolFinancialInfo]);
  const creditsFinancialInfo = useMemo(() => tabsData[creditsLevelandStep] ?? [], [creditsLevelandStep, tabsData]);
  const getValueForModal = useCallback(
    (creditName: string) => {
      if (!creditName) return nolRtpTotal;
      const credits = creditsFinancialInfo.filter(
        (record) => record.columnName === 'rtp' && record.creditName === creditName
      );
      let total = 0;
      for (const row of credits) {
        total += Number(row.value);
      }

      return total;
    },
    [creditsFinancialInfo, nolRtpTotal]
  );

  const getRtpTotal = useCallback(
    (rowName: string) => {
      if (rowName === nolInterlinkedRowName) return nolRtpTotal;
      return getValueForModal(rowName);
    },
    [getValueForModal, nolRtpTotal]
  );

  const isRowNolOrCredits = (row: Row) => {
    if (row.name === nolInterlinkedRowName) return true;
    if (row.creditName === 'state.credits') return true;
    return false;
  };

  useEffect(() => {
    const withCompletion: SubJurisdictionWithCompletion[] = states?.map((state) => ({
      ...state,
      isCompleted: Boolean(completionStatus?.state.rtp?.[state.id])
    }));

    setStatesWithCompletion(withCompletion);

    if (!currentState) {
      setCurrentState(withCompletion[0]);
    }
  }, [states, currentState, completionStatus]);

  useEffect(() => {
    let rtpCount = 0;
    for (const rtpElement of nolRtpValues) {
      rtpCount += Number(rtpElement.value);
    }

    setNolRtpTotal(rtpCount);
  }, [nolRtpValues]);

  const renderCellwithRtpColumnIcons = useCallback(
    (
      row: Row,
      value: number,
      column: Column,
      failedCells: FailedCells,
      onCellChange: TableProps['onCellChange'],
      onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
      renderOpts: any,
      rows: Row[],
      onCellClick?: TableProps['onCellClick']
      // eslint-disable-next-line max-params
    ) => {
      const flaggedRenderCell = renderCell;

      row.renderCell = row.renderCell ?? flaggedRenderCell;

      return shouldShowRtpIcon && isRowNolOrCredits(row) ? (
        <RtpColumnIcons
          {...{
            ignoreRenderCell: true,
            rightArrow: true,
            isTotalRow: column.headerName === 'Variance',
            value,
            column,
            failedCells,
            renderOpts,
            rows,
            row,
            buttonRef,
            totalDifference: getRtpTotal(String(row.name)),
            onCellChange,
            onCellOrCommentBlur,
            onCellClick,
            handleIconButtonClick: (event) => {
              buttonRef.current = event.currentTarget;
              setCreditNameForModal(row.name?.includes('Net Operating Loss') ? '' : String(row?.name));
              setIsDialogOpen((prevIsDialogOpen) => !prevIsDialogOpen);
            }
          }}
        />
      ) : (
        row.renderCell(
          row,
          value,
          column,
          failedCells,
          onCellChange,
          onCellOrCommentBlur,
          renderOpts,
          rows,
          '',
          shouldShowRtpIcon,
          onCellClick,
          true
        )
      );
    },
    [getRtpTotal, shouldShowRtpIcon]
  );

  const columns = getRTPAttributeColumns(
    t,
    !stepCompletionStatus.status,
    shouldShowRtpIcon,
    renderCellwithRtpColumnIcons
  );

  const rows = makeRows({
    t,
    columns,
    source,
    dispatch,
    className: classes.linkButton,
    stepCompletionStatus: stepCompletionStatus.status
  });

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    level: LEVEL,
    rows,
    step: STEP,
    jurisdictionId: currentState?.id,
    t,
    disablePriorYearError
  };
  const isFetchLoading = Boolean(rtpIsFetchLoading || dynamicIsFetchLoading || federalRtpIsFetchLoading);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <LoadingWrapper isLoading={isFetchLoading}>
        <ContentAndTitle
          title={
            currentState && (
              <TabTitle
                isStateRTP
                currencyIsoCode={currencyIsoCode}
                shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.state.rtp}
                title={
                  <>
                    {t('rtp')}
                    <Box component="span" ml={2}>
                      {currentState?.name}
                    </Box>
                  </>
                }
                isCompleted={stepCompletionStatus.status}
                onCompletionChange={(checked) => {
                  dispatch(
                    setEntityCompletionStatus({
                      ...stepCompletionStatus,
                      newStatus: checked
                    })
                  );
                }}
              />
            )
          }
        >
          <Box display="flex" overflow="hidden">
            <StateSelector states={statesWithCompletion} currentState={currentState} onSelect={setCurrentState} />
            <TableWithComment
              columns={columns}
              failedCells={failedCells}
              rows={rows}
              hideActionsMenu={stepCompletionStatus.status}
              onCellOrCommentBlur={(params) => {
                handleOnCellOrCommentBlurForEntityDetails({
                  ...dataForMethods,
                  ...params
                });
              }}
            />
          </Box>
        </ContentAndTitle>
      </LoadingWrapper>
      <InterlinkedDataDialog
        ref={dialogRef}
        open={isDialogOpen}
        handleClose={handleClose}
        anchorEl={buttonRef.current}
        icon={<NotEqualIcon />}
        creditName={creditNameForModal}
        value={getRtpTotal(creditNameForModal) ?? 0}
        level={LEVEL}
        step={STEP}
        onBalanceClick={() => {
          setIsBalanceModalOpen(true);
          return null;
        }}
        onViewClick={() => {
          history.push(`/entity-details/${entityNumber}/state/${creditNameForModal === '' ? 'nol' : 'credits'}`);
          return null;
        }}
      />
      <BalanceCalculationsModal
        currentStep={creditNameForModal ? 'credits' : 'nol'}
        level={LEVEL}
        steps={INTERLINKED_DATA_STEPS}
        entityId={entityId}
        open={isBalanceModalOpen}
        setOpen={setIsBalanceModalOpen}
        entityNumber={entityNumber}
        total={getValueForModal(creditNameForModal) ?? 0}
        creditName={creditNameForModal}
        currentState={currentState}
      />
    </>
  );
};

export default StateReturnToProvision;
