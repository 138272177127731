import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { DataAttributeName } from './UserRoleStylesProvider.interfaces';

import { selectUserRoleUuid } from '../../selectors';

const makeRoleKey = ({ dataAttributeName, roleUuid }: { dataAttributeName: DataAttributeName; roleUuid: string }) =>
  `[data-roles-${dataAttributeName}~="${roleUuid}"]`;

const UserRoleStylesProvider = ({ children }: { children: JSX.Element }) => {
  const roleUuid = useSelector(selectUserRoleUuid)!;

  // @global does not work with builder functions for selectors, so cannot call useStyles as useStyles({ roleUuid }),
  // thus makeStyles (which defines the selectors and their css properties) is not called outside the component as usual,
  // but here, where the roleUuid value is available
  const useStyles = makeStyles((_theme) => ({
    '@global': {
      [makeRoleKey({ dataAttributeName: 'hide-element', roleUuid })]: {
        display: 'none'
      },
      [makeRoleKey({ dataAttributeName: 'disable-element', roleUuid })]: {
        pointerEvents: 'none',
        opacity: 0.65
      },
      [makeRoleKey({ dataAttributeName: 'disable-element', roleUuid: 'finalizedContainer' })]: {
        pointerEvents: 'none',
        opacity: 0.65
      },
      [makeRoleKey({ dataAttributeName: 'disable-element', roleUuid: 'interlinkedDataWarning' })]: {
        pointerEvents: 'none',
        opacity: 0.65
      },
      [`${makeRoleKey({ dataAttributeName: 'disable-radio-button-element', roleUuid })}`]: {
        pointerEvents: 'none',
        '& svg:nth-child(1)': {
          // outer circle
          opacity: 0.45,
          color: '#666666'
        },
        '& svg:nth-child(2)': {
          // checked/inner circle
          opacity: 0.45,
          color: '#666666'
        }
      },
      [`${makeRoleKey({ dataAttributeName: 'disable-radio-button-element', roleUuid: 'finalizedContainer' })}`]: {
        pointerEvents: 'none',
        '& svg:nth-child(1)': {
          // outer circle
          opacity: 0.45,
          color: '#666666'
        },
        '& svg:nth-child(2)': {
          // checked/inner circle
          opacity: 0.45,
          color: '#666666'
        }
      },
      [makeRoleKey({ dataAttributeName: 'gray-out-all-td', roleUuid })]: {
        '& td': {
          background: '#FAFAFA !important', // `!important` necessary for overriding MUI styles
          borderBottom: 'none',
          pointerEvents: 'none'
        }
      },
      [makeRoleKey({ dataAttributeName: 'disable-table-input', roleUuid })]: {
        '& td': {
          pointerEvents: 'none',
          position: 'relative',
          backgroundColor: '#F5F5F5 !important'
        }
      },
      [makeRoleKey({ dataAttributeName: 'disable-pointer-events', roleUuid })]: {
        pointerEvents: 'none'
      }
      // Add additional role attribute CSS here.
      // Remember to add the necessary data-attributes to the elements, as well as the `DataAttributeName` type.
    }
  }));

  useStyles();

  return children;
};

export default UserRoleStylesProvider;
