import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar as MuiAvatar, Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DebugDataModal } from './components/DebugDataModal';

import { useContainers, useEntities } from '../../hooks';
import { selectAuthInfo } from '../../selectors/authentication';
import { selectInterlinkedDataWarnings } from '../../selectors/ui';
import LocationService from '../../services/location';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    fontSize: '18px',
    width: '36px',
    height: '36px'
  }
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1)
  }
}))((props) => (
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children?: ReactNode; getContentAnchorEl: ... Remove this comment to see the full error message
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const Avatar = () => {
  const classes = useStyles();
  const avatar = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { email } = useSelector(selectAuthInfo);
  const [debugDataModalOpen, setDebugDataModalOpen] = useState(false);
  const [debugData, setDebugData] = useState('');
  const { currentContainer } = useContainers();
  const { finalizeContainer: finalizeContainerFlag } = useFlags();
  const { entities } = useEntities();
  const interlinkedDataWarnings = useSelector(selectInterlinkedDataWarnings) || { nol: [], credits: [] };
  const hasInterlinkedDataWarnings =
    interlinkedDataWarnings.nol?.length > 0 || interlinkedDataWarnings.credits?.length > 0;

  function handleCloseMenu() {
    setMenuOpen(false);
  }

  return (
    <>
      <Button
        aria-haspopup
        aria-controls="simple-menu"
        onClick={(event) => {
          if (event.shiftKey) {
            const debugData = {
              currentContainer,
              entities
            };
            setDebugData(JSON.stringify(debugData, null, 2));
            setDebugDataModalOpen(true);
            event.stopPropagation();
            return;
          }

          setMenuOpen(true);
        }}
      >
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <MuiAvatar ref={avatar} className={classes.avatar}>
          {email?.[0]?.toUpperCase()}
        </MuiAvatar>
      </Button>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; keepMounted: true; anch... Remove this comment to see the full error message */}
      <StyledMenu keepMounted anchorEl={avatar.current} open={menuOpen} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            LocationService.logout();
          }}
        >
          {t('Log out')}
        </MenuItem>
      </StyledMenu>
      <DebugDataModal
        isOpen={debugDataModalOpen}
        data={debugData}
        hasFinalizeContainerButton={
          finalizeContainerFlag && !hasInterlinkedDataWarnings && !currentContainer?.isFinalized
        }
        onClose={() => {
          setDebugDataModalOpen(false);
        }}
      />
    </>
  );
};

export default Avatar;
