import { createReducer } from '@reduxjs/toolkit';

import {
  toggleIsRfModalOpen,
  setIsTemporaryBalanceSheetTabOpen,
  setSubJurisdictionSidemenuSelectedId,
  toggleEntityDetailsGroupedView,
  turnOffEntityDetailsGroupedView,
  setShouldGroupCategoriesOnReports,
  categoryRowsReportToggle,
  resetCategoryRowsReportToggle,
  setDashboardSelectedCard,
  setCountriesData,
  setEntityDetailsGroupedView,
  setInterlinkedDataWarningEntities
} from './ui.actions';
import { uiInitialState } from './ui.initialState';

export const uiReducer = createReducer(uiInitialState, (builder) => {
  builder
    .addCase(toggleIsRfModalOpen, (state, action) => ({
      ...state,
      isRfModalOpen: action.payload
    }))
    .addCase(setIsTemporaryBalanceSheetTabOpen, (state, action) => ({
      ...state,
      isTemporaryBalanceSheetTabOpen: action.payload
    }))
    .addCase(setSubJurisdictionSidemenuSelectedId, (state, action) => ({
      ...state,
      subJurisdictionSidemenuSelectedId: action.payload
    }))
    .addCase(toggleEntityDetailsGroupedView, (state) => {
      state.isEntityDetailsGroupedViewToggled = !state.isEntityDetailsGroupedViewToggled;
    })
    .addCase(setEntityDetailsGroupedView, (state, action) => {
      state.isEntityDetailsGroupedViewToggled = Boolean(action.payload);
    })
    .addCase(turnOffEntityDetailsGroupedView, (state) => {
      state.isEntityDetailsGroupedViewToggled = false;
    })
    .addCase(setShouldGroupCategoriesOnReports, (state, action) => {
      state.shouldGroupCategoriesOnReports = action.payload;
    })
    .addCase(setDashboardSelectedCard, (state, action) => {
      state.dashboardSelectedCard = action.payload;
    })
    .addCase(categoryRowsReportToggle, (state, action) => {
      if (state.toggledCategoriesOnReports[action.payload]) {
        state.toggledCategoriesOnReports[action.payload] = false;
        return;
      }

      state.toggledCategoriesOnReports[action.payload] = true;
    })
    .addCase(resetCategoryRowsReportToggle, (state) => {
      state.toggledCategoriesOnReports = {};
    })
    .addCase(setCountriesData, (state, action) => {
      state.countriesData = action.payload;
    })
    .addCase(setInterlinkedDataWarningEntities, (state, action) => {
      state.interlinkedDataWarningEntities.nol = action.payload.nol;
      state.interlinkedDataWarningEntities.credits = action.payload.credits;
    });
});
