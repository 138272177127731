import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useEntities } from '../../hooks';
import { setInterlinkedDataWarningEntities } from '../../redux/ui';
import { selectInterlinkedDataWarnings } from '../../selectors/ui';
import HTTPService, { LambdaResponse } from '../../services/http';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FEF7E0',
    color: theme.palette.text.secondary,
    height: '40px',
    '& .MuiButtonBase-root': {
      lineHeight: '18px'
    },
    marginTop: '5px',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  text: {
    textAlign: 'center',
    paddingLeft: '5px'
  }
}));

const InterlinkedDataWarning = () => {
  const { entities } = useEntities();
  const warningsByEntity = useSelector(selectInterlinkedDataWarnings) || { nol: [], credits: [] };
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HTTPService.request<LambdaResponse<any>>({
          method: 'get',
          apiUrlKey: 'apiUrl',
          relativePath: '/v1/container-rtp-financials'
        });
        dispatch(setInterlinkedDataWarningEntities(data));
      } catch (error: unknown) {
        console.error('Error fetching container data', error);
      }
    };

    void fetchData();
  }, [dispatch]);

  return (
    <>
      {warningsByEntity.nol.length > 0 && (
        <Box className={classes.container}>
          <Typography className={classes.text} variant="caption">
            {t('interlinked-data-warning-nol')}
          </Typography>
          <Typography className={classes.text} variant="caption">
            {warningsByEntity.nol
              .map((entityId: string) => `"${entities.find((entity) => entity.entityId === entityId)?.name ?? ''}"`)
              .join(', ')}
          </Typography>
        </Box>
      )}
      {warningsByEntity.credits.length > 0 && (
        <Box className={classes.container}>
          <Typography className={classes.text} variant="caption">
            {t('interlinked-data-warning-credits')}
          </Typography>
          <Typography className={classes.text} variant="caption">
            {warningsByEntity.credits
              .map((entityId: string) => `"${entities.find((entity) => entity.entityId === entityId)?.name ?? ''}"`)
              .join(', ')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InterlinkedDataWarning;
