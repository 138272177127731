import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '.';

import BalanceCalculationsModal from './BalanceCalculationsModal';
import InterlinkedDataDialog from './InterlinkedDataDialog';
import RtpColumnIcons from './RtpColumnIcons';

import { TableWithComment } from '../..';
import { ReactComponent as NotEqualIcon } from '../../../assets/img/IconNotEqual.svg';
import { calculateReturnToProvisionSum } from '../../../calculations';
import { FEDERAL_UUID, LEVELS, TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../hooks';
import { Entity, Row, Step } from '../../../models';
import { setEntityCompletionStatus } from '../../../redux/entitiesCompletionStatus';
import { updateFinancialData } from '../../../redux/financialData';
import { setInterlinkedDataWarningEntities } from '../../../redux/ui';
import { selectDoesUserHaveRole } from '../../../selectors';
import HTTPService, { LambdaResponse } from '../../../services/http';
import { RowForGetTableDataFromFinancialData, sortOnColumn } from '../../../utils';
import LoadingWrapper from '../../LoadingWrapper';
import { renderCell } from '../../Table/components/TableBody';
import { Column, FailedCells, TableProps } from '../../Table/Table.proptype';
import { TableWithCommentProps } from '../../TableWithComment/TableWithComment.proptype';
import { ProReadOnly, ProReviewer } from '../../UserRoleStylesProvider/constants';
import VirtualTable from '../../VirtualTable';
import { getCreditNamesFromFinancialData } from '../creditUtils';
import useClickOutsideDialog from '../hooks/useClickOutsideDialog';
import {
  makeSectionTitle,
  emptySectionHeader,
  EntityNumberRouteMatch,
  RTPAccountShape,
  RowsForCreateSourceObject,
  createSourceObject,
  getRowNamesAndSectionsFromLevelSteps,
  renderRTPPercentageCell,
  makeSectionTitleWithCreditName,
  getRTPAttributeColumns
} from '../utils';
import { getLinkButtonCssProps } from '../utils/styles';

type Source = {
  ptbi: RTPAccountShape[];
  permanent: RTPAccountShape[];
  temporary: RTPAccountShape[];
  stateTaxDeduction: RTPAccountShape;
  netOperatingLoss: RTPAccountShape;
  federalTaxRate: RTPAccountShape;
  credits: RTPAccountShape[];
  taxEffectedAdjustments: RTPAccountShape[];
};

const rowsToAlwaysDisplay: RowsForCreateSourceObject = {
  ptbi: [{ name: 'Pre-Tax Book Income / (Loss)' }, { name: 'Adjustments' }],
  stateTaxDeduction: { name: 'State Tax Deduction' },
  netOperatingLoss: { name: 'Net Operating Loss' },
  federalTaxRate: { name: 'Prior Year Federal Tax Rate' }
};

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const isRowNolOrCredits = (row: Row) => {
  if (row.name === (rowsToAlwaysDisplay.netOperatingLoss as RowForGetTableDataFromFinancialData)?.name) return true;
  if (row.creditName === 'state.credits') return true;
  return false;
};

// eslint-disable-next-line max-params
function makeRows(
  t: TFunction,
  columns: Column[],
  source: Source,
  dispatch?: any,
  className?: string,
  stepCompletionStatus?: boolean
) {
  if (!source) {
    return [];
  }

  const adjustedPtbi = calculateReturnToProvisionSum({ data: source.ptbi });
  const totalPermanent = calculateReturnToProvisionSum({ data: source.permanent });
  const totalTemporary = calculateReturnToProvisionSum({ data: source.temporary });
  const taxable = calculateReturnToProvisionSum({
    data: [adjustedPtbi, totalPermanent, totalTemporary, source.stateTaxDeduction, source.netOperatingLoss]
  });
  const taxBeforeCredits = {
    taxProvision: source.federalTaxRate.taxProvision * taxable.taxProvision,
    taxReturn: source.federalTaxRate.taxReturn * taxable.taxReturn
  };
  const totalCredits = calculateReturnToProvisionSum({ data: source.credits });
  const totalTaxEffectedAdjustments = calculateReturnToProvisionSum({ data: source.taxEffectedAdjustments });
  const tax = calculateReturnToProvisionSum({ data: [taxBeforeCredits, totalCredits, totalTaxEffectedAdjustments] });

  return [
    makeSectionTitle(t('Pre-Tax Book Income / (Loss)')),
    ...source.ptbi,
    {
      isTotal: true,
      name: t('Adjusted Pre-Tax Book Income / (Loss) '),
      ...adjustedPtbi,
      categorizable: false
    },

    makeSectionTitleWithCreditName({
      title: t('Permanent Differences'),
      options: { withImportLink: true, stepCompletionStatus },
      dispatch,
      className,
      creditName: 'federal.permanent'
    }),
    ...sortOnColumn(source.permanent, columns[0]),
    { isTotal: true, name: t('Total Permanent Differences'), ...totalPermanent },

    makeSectionTitleWithCreditName({
      title: t('Temporary Differences'),
      options: { withImportLink: true, stepCompletionStatus },
      dispatch,
      className,
      creditName: 'federal.temporary'
    }),
    ...sortOnColumn(source.temporary, columns[0]),
    { isTotal: true, name: t('Total Temporary Differences'), ...totalTemporary },

    emptySectionHeader,
    source.stateTaxDeduction,

    emptySectionHeader,
    source.netOperatingLoss,

    emptySectionHeader,
    { isTotal: true, name: t('Federal Taxable Income / (Loss)'), ...taxable, categorizable: false },

    emptySectionHeader,
    {
      ...source.federalTaxRate,
      categorizable: false,
      renderCell: renderRTPPercentageCell
    },

    emptySectionHeader,
    {
      isTotal: true,
      name: t('Federal Tax Expense / (Benefit) before Credits'),
      categorizable: false,
      ...taxBeforeCredits
    },

    makeSectionTitle(t('Credits')),
    ...sortOnColumn(source.credits, columns[0]),
    { isTotal: true, name: t('Total Credits'), ...totalCredits, categorizable: false },

    makeSectionTitle(t('Tax-Effected Adjustments')),
    ...sortOnColumn(source.taxEffectedAdjustments, columns[0]),
    {
      isTotal: true,
      name: t('Total Tax-Effected Adjustments'),
      ...totalTaxEffectedAdjustments,
      categorizable: false
    },

    emptySectionHeader,
    { isTotal: true, name: t('Federal Tax Expense / (Benefit)'), ...tax, categorizable: false }
  ];
}

type FederalReturnToProvisionProps = {
  entityId: Entity['entityId'];
};

const LEVEL = LEVELS.FEDERAL;
const STEPS: Step[] = ['rtp', 'permanent', 'temporary', 'credits', 'tax-effected', 'nol'];
const STEP = STEPS[0];
const LevelAndStep = `${LEVEL}.${STEP}`;
const nolLevelAndStep = `${LEVEL}.nol`;
const creditsLevelandStep = `${LEVEL}.credits`;

const FederalReturnToProvision = ({ entityId }: FederalReturnToProvisionProps) => {
  const { t } = useTranslation();
  const { prov2818InterlinkData, prov3349VirtualTable, prov4280OnlyEfeReportsView: disablePriorYearError } = useFlags();
  const shouldShowRtpIcon = prov2818InterlinkData;
  const [nolRtpTotal, setNolRtpTotal] = useState<number>();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const classes = useStyles({ isUserReadOnly });

  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const buttonRef: any = useRef<any>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [creditNameForModal, setCreditNameForModal] = useState('');
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const creditsFinancialInfo = useMemo(() => tabsData[creditsLevelandStep] ?? [], [tabsData]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HTTPService.request<LambdaResponse<any>>({
          method: 'get',
          apiUrlKey: 'apiUrl',
          relativePath: '/v1/container-rtp-financials'
        });
        dispatch(setInterlinkedDataWarningEntities(data));
      } catch (error: unknown) {
        console.error('Error fetching container data', error);
      }
    };

    void fetchData();
  }, [dispatch, financialInfo]);

  const getValueForModal = useCallback(
    (creditName: string) => {
      if (!creditName) return nolRtpTotal;
      const credits = creditsFinancialInfo.filter(
        (record) => record.columnName === 'rtp' && record.creditName === creditName
      );
      let total = 0;
      for (const row of credits) {
        total += Number(row.value);
      }

      return total;
    },
    [creditsFinancialInfo, nolRtpTotal]
  );

  const getRtpTotal = useCallback(
    (rowName: string) => {
      if (rowName === (rowsToAlwaysDisplay.netOperatingLoss as RowForGetTableDataFromFinancialData)?.name)
        return nolRtpTotal;
      return getValueForModal(rowName);
    },
    [getValueForModal, nolRtpTotal]
  );

  useClickOutsideDialog(dialogRef, setIsDialogOpen);

  const renderCellwithRtpColumnIcons = useCallback(
    (
      row: Row,
      value: number,
      column: Column,
      failedCells: FailedCells,
      onCellChange: TableProps['onCellChange'],
      onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
      onCellClickFiller: TableProps['onCellClick'], // Here because there are problems around shared render functions
      renderOpts: any,
      rows: Row[],
      onCellClick?: TableProps['onCellClick']
      // eslint-disable-next-line max-params
    ) => {
      row.renderCell = row.renderCell ?? renderCell;
      return shouldShowRtpIcon && isRowNolOrCredits(row) ? (
        <RtpColumnIcons
          {...{
            ignoreRenderCell: true,
            rightArrow: true,
            isTotalRow: column.headerName === 'Variance',
            value,
            column,
            failedCells,
            rows,
            renderOpts,
            row,
            buttonRef,
            totalDifference: getRtpTotal(String(row.name)),
            onCellChange,
            onCellOrCommentBlur,
            onCellClick,
            handleIconButtonClick: (event) => {
              buttonRef.current = event.currentTarget;
              // eslint-disable-next-line no-negated-condition
              setCreditNameForModal(row.name !== 'Net Operating Loss' ? String(row?.name) : '');
              setIsDialogOpen((prevIsDialogOpen) => !prevIsDialogOpen);
            }
          }}
        />
      ) : (
        row.renderCell(
          row,
          value,
          column,
          failedCells,
          onCellChange,
          onCellOrCommentBlur,
          onCellClick,
          renderOpts,
          rows,
          shouldShowRtpIcon,
          true
        )
      );
    },
    [getRtpTotal, shouldShowRtpIcon]
  );

  const columns = getRTPAttributeColumns(
    t,
    !stepCompletionStatus.status,
    shouldShowRtpIcon,
    renderCellwithRtpColumnIcons
  );

  const rowNamesFromOtherTabs = useMemo(() => {
    const rowName = getRowNamesAndSectionsFromLevelSteps(tabsData, LEVEL, [
      'permanent',
      'temporary.balanceSheet',
      'temporary.incomeStatement',
      'tax-effected'
    ]);

    return {
      permanent: rowName.permanent,
      temporary: [...rowName['temporary.balanceSheet'], ...rowName['temporary.incomeStatement']],
      taxEffectedAdjustments: rowName['tax-effected'],
      credits: getCreditNamesFromFinancialData(tabsData['federal.credits'] ?? []).map((name) => ({
        name,
        creditName: 'state.credits'
      }))
    };
  }, [tabsData]);

  const source = useMemo(
    () => createSourceObject(financialInfo, { ...rowsToAlwaysDisplay, ...rowNamesFromOtherTabs }),
    [financialInfo, rowNamesFromOtherTabs]
  ) as Source;

  const handleOnCellOrCommentBlur: TableWithCommentProps['onCellOrCommentBlur'] = ({ value, column, row }) => {
    dispatch(
      updateFinancialData(
        {
          accountId: row.accountId,
          // @ts-expect-error TODO - we need to figure out if row name can really be undefined
          rowName: row.name,
          creditName: row.creditName,
          // @ts-expect-error TODO - we need to figure out if column field can really be undefined
          columnName: column.field,
          rowId: row.rowId,
          level: LEVEL,
          step: STEP,
          value
        },
        entityId,
        disablePriorYearError
      )
    );
  };

  const openBalanceCalculations = useCallback(() => {
    setIsBalanceModalOpen(true);
    setIsDialogOpen(false);
  }, []);

  const nolFinancialInfo = useMemo(() => tabsData[nolLevelAndStep] ?? [], [tabsData]);
  const nolRtpValues = useMemo(() => {
    return nolFinancialInfo.filter(({ columnName }) => columnName === 'rtp');
  }, [nolFinancialInfo]);

  useEffect(() => {
    let rtpCount = 0;
    for (const rtpElement of nolRtpValues) {
      rtpCount += Number(rtpElement.value);
    }

    setNolRtpTotal(rtpCount);
  }, [nolRtpValues]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <LoadingWrapper isLoading={isFetchLoading}>
        <ContentAndTitle
          title={
            <TabTitle
              currencyIsoCode={currencyIsoCode}
              title={t(STEP)}
              isCompleted={stepCompletionStatus.status}
              shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.federal.rtp}
              onCompletionChange={(checked) => {
                dispatch(
                  setEntityCompletionStatus({
                    ...stepCompletionStatus,
                    newStatus: checked
                  })
                );
              }}
            />
          }
        >
          {prov3349VirtualTable ? (
            <VirtualTable
              includeNotesColumn
              level="federal"
              step="rtp"
              columns={columns}
              rows={makeRows(t, columns, source, dispatch, classes.linkButton, stepCompletionStatus.status)}
              failedCells={failedCells}
              hideActionsMenu={stepCompletionStatus.status}
              onCellOrCommentBlur={handleOnCellOrCommentBlur}
            />
          ) : (
            <TableWithComment
              columns={columns}
              failedCells={failedCells}
              rows={makeRows(t, columns, source, dispatch, classes.linkButton, stepCompletionStatus.status)}
              hideActionsMenu={stepCompletionStatus.status}
              onCellOrCommentBlur={handleOnCellOrCommentBlur}
            />
          )}
        </ContentAndTitle>
      </LoadingWrapper>
      <InterlinkedDataDialog
        ref={dialogRef}
        open={isDialogOpen}
        handleClose={handleClose}
        anchorEl={buttonRef.current}
        icon={<NotEqualIcon />}
        creditName={creditNameForModal}
        value={getValueForModal(creditNameForModal) ?? 0}
        level={LEVEL}
        step={STEP}
        onBalanceClick={openBalanceCalculations}
        onViewClick={() => {
          history.push(`/entity-details/${entityNumber}/federal/${creditNameForModal === '' ? 'nol' : 'credits'}`);
          return null;
        }}
      />
      <BalanceCalculationsModal
        currentStep={creditNameForModal ? 'credits' : 'nol'}
        level={LEVEL}
        steps={STEPS}
        entityId={entityId}
        open={isBalanceModalOpen}
        setOpen={setIsBalanceModalOpen}
        entityNumber={entityNumber}
        total={getValueForModal(creditNameForModal) ?? 0}
        creditName={creditNameForModal}
      />
    </>
  );
};

export default FederalReturnToProvision;
