import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
  container: {
    alignContent: 'center',
    backgroundColor: '#FEF7E0',
    height: '40px',
    color: theme.palette.text.secondary,
    width: 'fit-content',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: '10px'
  },
  text: {
    textAlign: 'left',
    padding: '10px'
  }
}));

const DashboardValuesNotice = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    prov4058HideEfeForInterim,
    prov4087HideEfeForFinalizedBeforeEfeContainers,
    prov4280OnlyEfeReportsView
  } = useFlags();
  const areEfeReportsEnabled =
    (!prov4058HideEfeForInterim && !prov4087HideEfeForFinalizedBeforeEfeContainers) || prov4280OnlyEfeReportsView;
  return areEfeReportsEnabled ? (
    <Box className={classes.container}>
      <Typography className={classes.text} variant="h3">
        {`${t('dashboard-values-notice')}`}
      </Typography>
    </Box>
  ) : null;
};

export default DashboardValuesNotice;
