import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, StateSelector, TabTitle } from '../';
import { TableWithComment } from '../../..';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { calculateEndingBalanceForCredits } from '../../../../calculations';
import { LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { StateTabProps, Step, SubJurisdictionWithCompletion, Column, Row } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { setInterlinkedDataWarningEntities } from '../../../../redux/ui';
import HTTPService, { LambdaResponse } from '../../../../services/http';
import LoadingWrapper from '../../../LoadingWrapper';
import { renderCell } from '../../../Table/components/TableBody';
import { FailedCells, TableProps } from '../../../Table/Table.proptype';
import useClickOutsideDialog from '../../hooks/useClickOutsideDialog';
import { TaxPeriodRow } from '../../models';
import {
  EntityNumberRouteMatch,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails
} from '../../utils';
import BalanceCalculationsModal from '../BalanceCalculationsModal';
import InterlinkedDataDialog from '../InterlinkedDataDialog';
import RtpColumnIcons from '../RtpColumnIcons';

const LEVEL = LEVELS.STATE;
const STEP: Step = 'nol';
const STEPS: Step[] = [STEP, 'rtp'];
const LevelAndStep = `${LEVEL}.${STEP}`;

export const getNolColumns = (
  t: any,
  {
    shouldShowRtpIcon,
    rtpNolDifference,
    setIsDialogOpen,
    completionStatus,
    hasNewRow,
    prov2826TarfEnhancements,
    prov4011TotalRowFix
  }: any
) => {
  return prov2826TarfEnhancements
    ? [
        {
          field: 'name',
          headerName: t('Tax Period'),
          isNewRowEditable: true
        },
        {
          field: 'beginningBalance',
          headerName: t('Beginning Balance'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'rtp',
          headerName: t('Return to Provision'),
          isEditable: !completionStatus,
          isNumber: true,
          shouldShowRtpIcon,
          renderCell: (
            row: Row,
            value: number,
            column: Column,
            failedCells: FailedCells,
            onCellChange: TableProps['onCellChange'],
            onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
            renderOpts: any,
            rows: Row[],
            onCellClick?: TableProps['onCellClick']
            // eslint-disable-next-line max-params
          ) => {
            return shouldShowRtpIcon ? (
              <RtpColumnIcons
                {...{
                  ignoreRenderCell: true,
                  isTotalRow: row.total === true,
                  value,
                  column,
                  failedCells,
                  renderOpts,
                  rows,
                  row,
                  totalDifference: rtpNolDifference,
                  disableButton: hasNewRow,
                  onCellChange,
                  onCellOrCommentBlur,
                  onCellClick,
                  handleIconButtonClick: () => {
                    setIsDialogOpen((prevIsDialogOpen: boolean) => !prevIsDialogOpen);
                  }
                }}
              />
            ) : (
              renderCell(
                row,
                value,
                column,
                failedCells,
                onCellChange,
                onCellOrCommentBlur,
                renderOpts,
                rows,
                '',
                onCellClick,
                true
              )
            );
          }
        },
        {
          field: 'generatedAmount',
          headerName: t('Amount Generated in Current Year'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'usedAmount',
          headerName: t('Amount (Used) in Current Year'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'deferredOnlyAdjustment',
          headerName: t('Deferred Only Adjustment'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'oci',
          headerName: t('OCI'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'goodwill',
          headerName: t('Goodwill'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'fin48',
          headerName: t('FIN48'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'balanceSheetOnlyAdjustment',
          headerName: t('Balance Sheet Only Adjustment'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'endingBalance',
          headerName: t('Ending Balance'),
          getValue: (row: any) =>
            'endingBalance' in row ? row.endingBalance : calculateEndingBalanceForCredits(row).endingBalance,
          isNumber: true
        },
        {
          field: 'remaining',
          headerName: t('Carry Over Period Remaining'),
          isEditable: !completionStatus,
          isNumber: true,
          renderCell: (
            row: Row,
            value: number,
            column: Column,
            failedCells: FailedCells,
            onCellChange: TableProps['onCellChange'],
            onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
            renderOpts: any,
            rows: Row[],
            onCellClick?: TableProps['onCellClick']
            // eslint-disable-next-line max-params
          ) => {
            const normalOrTotalCell = Object.hasOwn(row, 'total') && prov4011TotalRowFix ? 0 : value;
            return renderCell(
              row,
              normalOrTotalCell,
              column,
              failedCells,
              onCellChange,
              onCellOrCommentBlur,
              renderOpts,
              rows,
              '',
              onCellClick,
              true
            );
          }
        }
      ]
    : [
        {
          field: 'name',
          headerName: t('Tax Period'),
          isNewRowEditable: true
        },
        {
          field: 'beginningBalance',
          headerName: t('Beginning Balance'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'rtp',
          headerName: t('Return to Provision'),
          isEditable: !completionStatus,
          isNumber: true,
          shouldShowRtpIcon,
          renderCell: (
            row: Row,
            value: number,
            column: Column,
            failedCells: FailedCells,
            onCellChange: TableProps['onCellChange'],
            onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
            renderOpts: any,
            rows: Row[],
            onCellClick?: TableProps['onCellClick']
            // eslint-disable-next-line max-params
          ) => {
            return shouldShowRtpIcon ? (
              <RtpColumnIcons
                {...{
                  ignoreRenderCell: true,
                  isTotalRow: row.total === true,
                  value,
                  column,
                  failedCells,
                  renderOpts,
                  rows,
                  row,
                  totalDifference: rtpNolDifference,
                  disableButton: hasNewRow,
                  onCellChange,
                  onCellOrCommentBlur,
                  onCellClick,
                  handleIconButtonClick: () => {
                    setIsDialogOpen((prevIsDialogOpen: boolean) => !prevIsDialogOpen);
                  }
                }}
              />
            ) : (
              renderCell(
                row,
                value,
                column,
                failedCells,
                onCellChange,
                onCellOrCommentBlur,
                renderOpts,
                rows,
                '',
                onCellClick,
                true
              )
            );
          }
        },
        {
          field: 'generatedAmount',
          headerName: t('Amount Generated in Current Year'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'usedAmount',
          headerName: t('Amount (Used) in Current Year'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'deferredOnlyAdjustment',
          headerName: t('Deferred Only Adjustment'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'balanceSheetOnlyAdjustment',
          headerName: t('Balance Sheet Only Adjustment'),
          isEditable: !completionStatus,
          isNumber: true
        },
        {
          field: 'endingBalance',
          headerName: t('Ending Balance'),
          getValue: (row: any) =>
            'endingBalance' in row ? row.endingBalance : calculateEndingBalanceForCredits(row).endingBalance,
          isNumber: true
        },
        {
          field: 'remaining',
          headerName: t('Carry Over Period Remaining'),
          isEditable: !completionStatus,
          isNumber: true,
          renderCell: (
            row: Row,
            value: number,
            column: Column,
            failedCells: FailedCells,
            onCellChange: TableProps['onCellChange'],
            onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
            renderOpts: any,
            rows: Row[],
            onCellClick?: TableProps['onCellClick']
            // eslint-disable-next-line max-params
          ) => {
            const normalOrTotalCell = Object.hasOwn(row, 'total') && prov4011TotalRowFix ? 0 : value;
            return renderCell(
              row,
              normalOrTotalCell,
              column,
              failedCells,
              onCellChange,
              onCellOrCommentBlur,
              renderOpts,
              rows,
              '',
              onCellClick,
              true
            );
          }
        }
      ];
};

const StateNetOperatingLoss = ({ entityId, states }: StateTabProps) => {
  const { t } = useTranslation();
  const [currentState, setCurrentState] = useState<SubJurisdictionWithCompletion>();
  const [statesWithCompletion, setStatesWithCompletion] = useState<SubJurisdictionWithCompletion[]>([]);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;

  const [rows, setRows] = useState<TaxPeriodRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const [rtpNolDifference, setRtpNolDifference] = useState<number>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { entityCompletionStatus: currentEntity, stepCompletionStatus } = useCompletionStatus(
    entityNumber,
    LevelAndStep,
    currentState?.id
  );
  const { completionStatus: stateCompletionStatus } = currentEntity;

  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS, currentState?.id);

  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['state.rtp'] ?? [], [tabsData]);

  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS)[STEP], [tabsData]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [
    financialInfo,
    rowNames
  ]) as TaxPeriodRow[];

  const dialogRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const anchorEl = tableRef.current;
  const {
    prov2818InterlinkData,
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov2826TarfEnhancements,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4011TotalRowFix
  } = useFlags();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HTTPService.request<LambdaResponse<any>>({
          method: 'get',
          apiUrlKey: 'apiUrl',
          relativePath: '/v1/container-rtp-financials'
        });
        dispatch(setInterlinkedDataWarningEntities(data));
      } catch (error: unknown) {
        console.error('Error fetching container data', error);
      }
    };

    void fetchData();
  }, [dispatch, financialInfo]);

  useClickOutsideDialog(dialogRef, setIsDialogOpen);

  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  useEffect(() => {
    const withCompletion = states?.map((state) => ({
      ...state,
      isCompleted: Boolean(stateCompletionStatus?.state?.nol?.[state.id])
    }));

    setStatesWithCompletion(withCompletion);

    if (!currentState) {
      setCurrentState(withCompletion[0]);
    }
  }, [states, currentState, stateCompletionStatus]);

  useEffect(() => {
    const nolTaxReturnRecord = rtpFinancialInfo.find(
      (record) => record.columnName === 'taxReturn' && record.rowName === 'Post-Apportioned Net Operating Loss'
    );
    const nolTaxProvisionRecord = rtpFinancialInfo.find(
      (record) => record.columnName === 'taxProvision' && record.rowName === 'Post-Apportioned Net Operating Loss'
    );
    const nolTaxReturnValue = nolTaxReturnRecord ? Number(nolTaxReturnRecord.value) : 0;
    const nolTaxProvisionValue = nolTaxProvisionRecord ? Number(nolTaxProvisionRecord.value) : 0;
    const nolDifference = nolTaxReturnValue - nolTaxProvisionValue;
    setRtpNolDifference(nolDifference);
  }, [rtpFinancialInfo]);

  const shouldShowRtpIcon = prov2818InterlinkData;
  const columns = useMemo(() => {
    return getNolColumns(t, {
      shouldShowRtpIcon,
      rtpNolDifference,
      setIsDialogOpen,
      completionStatus: stepCompletionStatus.status,
      hasNewRow,
      prov2826TarfEnhancements,
      prov4011TotalRowFix
    });
  }, [
    hasNewRow,
    prov2826TarfEnhancements,
    prov4011TotalRowFix,
    rtpNolDifference,
    shouldShowRtpIcon,
    stepCompletionStatus.status,
    t
  ]);
  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    jurisdictionId: currentState?.id,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <LoadingWrapper isLoading={isFetchLoading}>
        <ContentAndTitle
          title={
            currentState ? (
              <TabTitle
                currencyIsoCode={currencyIsoCode}
                title={
                  <div data-testid="tab-title">
                    {t('nol')}
                    <Box component="span" ml={2}>
                      {currentState?.name}
                    </Box>
                  </div>
                }
                isCompleted={stepCompletionStatus.status}
                onCompletionChange={(checked) => {
                  dispatch(
                    setEntityCompletionStatus({
                      ...stepCompletionStatus,
                      newStatus: checked
                    })
                  );
                }}
              />
            ) : (
              ''
            )
          }
        >
          <Box display="flex" overflow="hidden">
            <StateSelector
              data-testid="state-selector"
              states={statesWithCompletion}
              currentState={currentState}
              onSelect={(state) => {
                if (hasNewRow) {
                  setRows(rows.filter(({ isNew }) => !isNew));
                  setHasNewRow(false);
                }

                setCurrentState(state);
              }}
            />
            <div ref={tableRef} style={{ overflow: 'auto' }}>
              <TableWithComment
                columns={columns}
                failedCells={failedCells}
                newRowButtonLabel={
                  stepCompletionStatus.status ? null : t(hasNewRow ? 'Save Tax Period' : 'Add Tax Period')
                }
                noRowLabel={t('No tax periods yet for _', currentState)}
                rows={rows}
                totalHeaderName={t('Total')}
                hideActionsMenu={stepCompletionStatus.status}
                handleOnRowDelete={(params) => {
                  handleOnRowDeleteForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
                onCellChange={(params) => {
                  if (!isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }
                }}
                onCellOrCommentBlur={(params) => {
                  if (isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }

                  handleOnCellOrCommentBlurForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
                onNewRowClick={() => {
                  handleNewRowForEntityDetails({
                    ...dataForMethods
                  });
                }}
              />
            </div>
          </Box>
        </ContentAndTitle>
      </LoadingWrapper>
      <InterlinkedDataDialog
        ref={dialogRef}
        open={isDialogOpen}
        handleClose={handleClose}
        anchorEl={anchorEl}
        icon={<NotEqualIcon />}
        value={rtpNolDifference ?? 0}
        level={LEVEL}
        step={STEP}
        onBalanceClick={() => {
          setIsBalanceModalOpen(true);
          return null;
        }}
        onViewClick={() => {
          history.push(`/entity-details/${entityNumber}/state/rtp`);
          return null;
        }}
      />
      <BalanceCalculationsModal
        currentStep={STEP}
        level={LEVEL}
        steps={STEPS}
        entityId={entityId}
        open={isBalanceModalOpen}
        setOpen={setIsBalanceModalOpen}
        entityNumber={entityNumber}
        total={rtpNolDifference ?? 0}
        currentState={currentState}
      />
    </>
  );
};

export default StateNetOperatingLoss;
